import pic from '../images/BORN FOR A PURPOSE.png'
import pic2 from '../images/Frame 220.png'
import pic3 from '../images/Frame 223.png'
import pic29 from '../images/Frame 224.png'
import pic4 from '../images/Frame 226.png'
import pic5 from '../images/Frame 227.png'
import pic6 from '../images/Frame 232.png'
import pic7 from '../images/Frame 220 (1).png'
import pic8 from '../images/DEPTHS OF GOD.png'
import pic9 from '../images/WHAT TO LIVE FOR 1.png'
import pic10 from '../images/DON’T FORGET PURPOSE.png'
import pic11 from '../images/GOD REMEMBERS.png'
import pic12 from '../images/In Christ Our Union.png'
import pic13 from '../images/Why We Do What We Do.png'
import pic14 from '../images/Investigating God’s Character.png'
import pic15 from '../images/KABAS SUBDUE.png'
import pic16 from '../images/Ministry Of the Word.png'
import pic17 from '../images/Our Supernatural HERITAGE.png'
import pic18 from '../images/RESPONSIBILITY & F0CUS.png'
import pic19 from '../images/RISK It ALL.png'
import pic20 from '../images/SHEPHERD OF ISRAEL.png'
import pic21 from '../images/The Supernatural Place.png'
import pic22 from '../images/The Supernatural beings.png'
import pic23 from '../images/The name of Jesus.png'
import pic24 from '../images/Understanding the COMMUNITY.png'
import pic25 from '../images/Do not Boast.jpg'
import pic26 from '../images/SWC.jpg'
import swc25 from '../images/SWC25.jpg'
import pic27 from '../images/MLR.jpg'
import pic28 from '../images/awt.png'
import picKC24 from '../images/kc2024.jpg'
import tund from '../images/TUND.jpg'
import pic0 from '../images/Redeeming The Times.jpg'
import audio1 from '../assets/aud1.mp3' 
import audio2 from '../assets/aud2.mp3' 
import audio3 from '../assets/aud3.mp3' 
import audio4 from '../assets/aud4.mp3' 
const messages = [
     {
        img: swc25,
        audioSrc: [
            '/SWC2025/EXPECTATIONS IN CHRISTIAN MEETINGS(SWC 25).mp3',
            '/SWC2025/GOD WANTS MEN!(SWC 25).mp3',
            '/SWC2025/HE GAVE THEM POWER(SWC 25).mp3',
            '/SWC2025/HOLY SPIRIT, HELPER OF MEN(SWC 25).mp3',
            '/SWC2025/IDENTITY & PURPOSE(SWC 25).mp3',
            '/SWC2025/STAYING FAITHFUL TO YOUR MINISTRY(SWC 25).mp3',
        ],
        title: 'Soul Winners Conference 2025'
    },
     {
        img: tund,
        audioSrc: '/messages/TEACH US TO NUMBER OUR DAYS (NEW YEAR MESSAGE).mp3',
        title: 'TEACH US TO NUMBER OUR DAYS'
    },
    {
        img: picKC24,
        title: 'KATARTISMOS CONGRESS 2024',
        audioSrc: [
            '/KC2024/SPIRIT OF PRAYER AND SUPPLICATION (KATARTISMOS CHARGE 26TH).mp3',
            '/KC2024/A CHARGE ON PRAYER (KATARTSIMOS 2024).mp3',
            '/KC2024/BIBLE HERMENEUTICS 1.mp3',
            '/KC2024/PRAYER OF CONSECRATION, SIBLINGS AND PARENTS.mp3',
            '/KC2024/BIBLE HERMENEUTICS II.mp3',
            '/KC2024/IN THE LEAP YEAR.mp3',
            '/KC2024/WORD TO YOUNG MINISTERS.mp3',
            '/KC2024/CONCERNING SPIRITUALS (TONGUES, WORD OF WISDOM AND KNOWLEDGE).mp3',
            '/KC2024/GIFT AND GIFTINGS.mp3',
            "/KC2024/THE LORD'S BODY (HIS TEMPLE AND HIS REST).mp3",
            '/KC2024/TAKE HEED TO YOUR MINISTRY (THE BEKORA).mp3',
        ],
    },
     {
        img: pic5,
        audioSrc: '/messages/SPIRITUAL INTELLIGENCE (SPIRITUAL SENSITIVITY & WARFARE).mp3',
        title: 'SPIRITUAL INTELLIGENCE (SPIRITUAL SENSITIVITY & WARFARE)'
    },
    {
        img: pic5,
        audioSrc: "/messages/SPIRITUAL INTELLIGENCE II (PRAYER & JACOB'S VOW).mp3",
        title: "SPIRITUAL INTELLIGENCE II (PRAYER & JACOB'S VOW)"
    },
    {
        img: pic25,
        audioSrc: '/messages/DO NOT BOAST, RATHER PRAY (KATARTISMOS CONGRESS 2023).mp3',
        title: 'Do not Boast, Rather Pray'
    },
    {
        img: pic26,
        audioSrc: [
            '/SWC2024/SOUL WINNERS CONFERENCE (T1).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (WHAT WILL YOU DO WITH THE HOLY SPIRIT) (T2).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T3).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T4).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T8).mp3',
        ],
        title: 'Soul Winners Conference 2024'
    },
    {
        img: pic4,
        audioSrc: '/messages/Revive Your Work.mp3',
        title: 'Revive your Work'
    },
    {
        img: pic27,
        audioSrc: [
            "/MLR2024/MLR '24, TRACK 1.mp3",
            "/MLR2024/MLR '24, TRACK 2.mp3",
            "/MLR2024/MLR '24, TRACK 3.mp3",
            "/MLR2024/MLR '24, TRACK 4.mp3",
            "/MLR2024/MLR '24, TRACK 5.mp3",
            "/MLR2024/MLR '24, TRACK 6.mp3",
            "/MLR2024/MLR '24, TRACK 8 (LOOK NOT AT THYSELF).mp3",
            "/MLR2024/MLR '24, TRACK 9 (SUNDAY MORNING).mp3",
            "/MLR2024/MLR '24, TRACK 10 (CLOSING CHARGE).mp3",
        ],
        title: "Ministers' & Leaders' Retreat 2024"
    },
    {
        img: pic0,
        title: 'REDEEMING THE TIMES',
        audioSrc: [
            '/RTT/REDEEMING THE TIMES I.mp3',
            '/RTT/REDEEMING THE TIMES II.mp3',
        ],
    },
    {
        img: pic29,
        audioSrc: [
            '/COG/CLOUD OF GLORY, TRACK 1, NOVEMBER 2022.mp3',
            '/COG/CLOUD OF GLORY, DAY TWO (2), NOVEMBER 2022.mp3',
            '/COG/CLOUD OF GLORY, DAY THREE, NOVEMBER 2022.mp3',
        ],
        title: 'Cloud of Glory'
    },
    {
        img: pic8,
        audioSrc: '/messages/DEPTHS OF GOD.mp3',
        title: 'Depths of God'
    },
    {
        img: pic3,
        audioSrc: [
            "/COTW/Culture of the Word Track 1 (Basics Series) July 5, 2020.mp3",
            "/COTW/Culture of the Word Track 2 (Basics Series) July 8, 2020.mp3",
            "/COTW/Culture of the Word Track 3 Parable (Basic Series) July 15, 2020.mp3",
            "COTW/Culture of the Word Track 4 (Basics Series) August 5, 2020.mp3",
        ],
        title: 'Culture of Word'
    },
    {
        img: pic2,
        audioSrc: '/messages/Forming Habits. Building Character.mp3',
        title: 'Forming Habits and Building Character'
    },
    {
        img: pic7,
        audioSrc: [
            '/DOP/Dynamics of Prayer (2022) Track 1.mp3',
            '/DOP/Dynamics of Prayer (2022) Track 2.mp3',
            '/DOP/Dynamics of Prayer 3 March 2.mp3',
            '/DOP/The Dynamics Of Prayer 4 Mar 6 2022.mp3',
        ],
        title: 'The Dynamics of Prayer'
    },
    {
        img: pic28,
        audioSrc: [
            '/awt/As We Are Taught 1 (Readiness to Defend) January 12, 2022.mp3',
            '/awt/As We Are Taught 2 (Knowledge and Appraisal) January 26, 2022.mp3',
            '/awt/As We Are Taught 3 (Reteaching the Church in Exile) January 30, 2022.mp3',
        ],
        title: 'As We Are Taught'
    },
    // {
    //     img: pic6,
    //     audioSrc: '',
    //     title: 'Bold Confessios of Faith'
    // },
    // {
        //     img: pic9,
        //     audioSrc: '',
        //     title: 'What to Live For'
    // },
    // {
    //     img: pic10,
    //     audioSrc: '',
    //     title: "Don't Forget Purpose"
    // },
    // {
    //     img: pic11,
    //     audioSrc: '',
    //     title: 'GOD REMEMBERS'
    // },
    // {
    //     img: pic12,
    //     audioSrc: '',
    //     title: 'In Christ Our Union'
    // },
    // {
        //     img: pic13,
    //     audioSrc: '',
    //     title: 'Why We Do What We Do'
    // },
    // {
    //     img: pic14,
    //     audioSrc: '',
    //     title: 'Investigating God’s Character'
    // },
    // {
    //     img: pic15,
    //     audioSrc: '',
    //     title: 'KABAS SUBDUE'
    // },
    // {
    //     img: pic16,
    //     audioSrc: '',
    //     title: 'Ministry Of the Word'
    // },
    // {
    //     img: pic17,
    //     audioSrc: '',
    //     title: 'Our Supernatural Heritage'
    // },
    // {
    //     img: pic18,
    //     audioSrc: '',
    //     title: 'Responsibility and Focus'
    // },
    // {
    //     img: pic19,
    //     audioSrc: '',
    //     title: 'Risk it All'
    // },
    // {
    //     img: pic20,
    //     audioSrc: '',
    //     title: 'Gifts of the Spirit'
    // },
    // {
    //     img: pic21,
    //     audioSrc: '',
    //     title: 'The Supernatural Place'
    // },
    // {
    //     img: pic22,
    //     audioSrc: '',
    //     title: 'The Supernatural Beings'
    // },
    // {
    //     img: pic23,
    //     audioSrc: '',
    //     title: 'The name of Jesus'
    // },
    // {
    //     img: pic24,
    //     audioSrc: '',
    //     title: 'Understanding the Community'
    // },
]

export default messages