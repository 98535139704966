import React,{useState, useEffect } from 'react'
import AudioCard from './AudioCard'
import Pagination from '../../utils/Pagination'
import messages from '../../utils/Messages'
import { BsSearch } from 'react-icons/bs';


interface MsgProp {
     img: string; audioSrc: string; title: string;
}
const AudioDiv = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchKey, setSearchKey] = useState('')
    const [filteredMsgs, setFilteredMsgs] = useState<MsgProp | any>([])
    const itemsPerPage = 9;

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = e.target.value.toLowerCase();
        setSearchKey(keyword);
    }

    useEffect(() => {
        if (searchKey.trim() !== '') {
            const searchedMsgs = messages.filter(msg =>
                msg.title.toLowerCase().includes(searchKey)
            );
            setFilteredMsgs(searchedMsgs);
            setCurrentPage(1); // Reset to first page when searching
        } else {
            setFilteredMsgs([]);
        }
    }, [searchKey]);

    const [openPlaylistIndex, setOpenPlaylistIndex] = useState<number | null>(null); // Track open playlist

    const handleTogglePlaylist = (index: number) => {
        setOpenPlaylistIndex(openPlaylistIndex === index ? null : index);
    };

    // Determine which data set to show (filtered or all)
    const dataToShow = filteredMsgs.length > 0 || searchKey ? filteredMsgs : messages;
 
    // Calculate the current items to show
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataToShow.slice(indexOfFirstItem, indexOfLastItem);

    

    return (
        <div className="xl:px-[13%] lg:px-[10%] py-[2%] md:pt-[10%] ">
            <div className="flex sm:flex-row flex-col text-white gap-x-16 items-center justify-center mb-32 gap-y-8  ">
                <div className="flex items-center gap-x-5">
                    <span className="text-xl font-dmsans font-semibold">Search by :</span>
                    <div className=" bg-navyBlueX rounded-full px-3">
                        <select className="bg-navyBlueX rounded-2xl px-5 py-3 text- outline-none">
                            <option value="" key="">Keyword</option>
                            <option value="" key="">Date</option>
                        </select>
                    </div>
                </div>
                <div className="flex items-center border py-2 px-6 rounded-full  gap-x-2 max-w-64">
                    <BsSearch />
                    <input type="text " placeholder='search' className="outline-none border-none bg-transparent" onChange={handleSearch} />
                </div>
            </div>
            <div className="flex flex-wrap gap-y-16 justify-around gap-x-10 bg-navyBlueX py-16 rounded-[40px]">
                {
                    currentItems.map((audio: MsgProp, index: number) =>(
                        <AudioCard 
                            key={index} 
                            img={audio.img} 
                            audioSrc={audio.audioSrc} 
                            title={audio.title}
                            isPlaylistOpen={openPlaylistIndex === index} // Check if this playlist is open
                            togglePlaylist={() => handleTogglePlaylist(index)} // Toggle playlist
                        />
                    ))
                }
            </div>
            
            <div className="my-20 flex justify-center items-center">
                <Pagination
                    totalItems={messages.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default AudioDiv